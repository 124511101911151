import { CircularProgress } from '@mui/material';
import { Suspense, useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import PageLayoutWrapper from '../pages/PageLayoutWrapper'; // is header ?
import ProtectedRoute from './ProtectedRoute'; // is login ? 
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';

import HomePageMain from '../pages/homepageMain';
import HomePageWayward from '../pages/HomePageWayward';
import HomePage from '../pages/Homepage';
import { createRem } from '../utils/rem';

const Page404 = React.lazy(() => import('../pages/404'));
const CreateAvatar = React.lazy(() => import('../pages/createAvatar'));
const Test = React.lazy(() => import('../pages/test'));
const CreateVideo = React.lazy(() => import('../pages/createVideo'));
const Account = React.lazy(() => import('../pages/account'));
const Product = React.lazy(() => import('../pages/product'));
const VideoProduct = React.lazy(() => import('../pages/videoProduct'));
const TestVideo = React.lazy(() => import('../pages/createVideo/testVideo'));
const PreviewVideo = React.lazy(() => import('../pages/preview'));

const ManuallyAdd = React.lazy(() => import('../pages/test/manuallyAdd'));
const EditSlideshow = React.lazy(() => import('../pages/slideshow/editSlideshow'));
const CreateSliceshow = React.lazy(() => import('../pages/slideshow/createSlideshow'));

export default function MainRouter() {
  const auth = useSelector((state: RootState) => state.auth);
  const isAI = document.documentElement.dataset.eureka;
  
  const remChange = createRem(!auth.user);
  useEffect(()=>{
    window.addEventListener("resize", remChange);
    remChange();
    return () => {
      window.removeEventListener("resize", remChange);
    }
  }, [auth]);
  
  return (
    <Router>
      <Routes>
        <Route path="/create-avatar"
          element={
            <Suspense fallback={<CircularProgress />}>
              <ProtectedRoute element={
                <CreateAvatar />
              } />
            </Suspense>
          }
        />
        <Route path="/test"
          element={
            <Suspense fallback={<CircularProgress />}>
              <ProtectedRoute element={
                <Test />
              } />
            </Suspense>
          }
        />
        <Route path="/create-video"
          element={
            <Suspense fallback={<CircularProgress />}>
              <ProtectedRoute element={
                <CreateVideo />
              } />
            </Suspense>
          }
        />
        <Route path="/product"
          element={
            <Suspense fallback={<CircularProgress />}>
              <ProtectedRoute element={
                <Product />
              } />
            </Suspense>
          }
        />
        <Route path="/video-edit"
          element={
            <Suspense fallback={<CircularProgress />}>
              <ProtectedRoute element={ <VideoProduct /> } />
            </Suspense>
          }
        />
        <Route path="/account/*" element={
          <Suspense fallback={<CircularProgress />}>
            <Account />
          </Suspense>
        } />
        <Route path="/preview" element={
          <Suspense fallback={<CircularProgress />}>
            <PreviewVideo />
          </Suspense>
        } />

        <Route path="/test" element={
          <Suspense fallback={<CircularProgress />}>
            <TestVideo />
          </Suspense>
        } />

        <Route path="/add-material" element={
          <Suspense fallback={<CircularProgress />}>
            <ProtectedRoute element={
              <ManuallyAdd />
            } />
          </Suspense>
        } />

        <Route path="/create-slideshow" element={
          <Suspense fallback={<CircularProgress />}>
            <ProtectedRoute element={
              <CreateSliceshow />
            } />
          </Suspense>
        } />

        <Route path="/slideshow-edit" element={
          <Suspense fallback={<CircularProgress />}>
            <ProtectedRoute element={
              <EditSlideshow />
            } />
          </Suspense>
        } />

        <Route path="/create-ai-video" element={ <HomePageMain /> } />

        { auth.user ? (
          <Route path="/*"
            element={
              <ProtectedRoute element={
                <HomePage />
              } />
            }
          />
        ) : (
          <Route path="/*" element={ isAI ? <HomePageMain /> : <HomePageWayward /> } />
        ) }
        
        {/* <Route path="/*"
          element={
            <Suspense fallback={<CircularProgress />}>
              <Page404 status={0} />
            </Suspense>
          }
        /> */}
      </Routes>
    </Router>
  );
}
